import React, { useEffect, useState } from "react";
import { Avatar, Box, IconButton, Menu, MenuItem, Stack } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Gear } from "phosphor-react";
import { Nav_Buttons, Profile_Menu } from "../../data";
import Logo from "../../assets/Images/logo.png";
import { useNavigate, useSearchParams } from "react-router-dom";
import ThemeSettings from "../../components/settings";
import { useDispatch, useSelector } from "react-redux";
import { changeSelection } from "../../components/Conversation/contactSlice";

const getPath = (index) => {
  switch (index) {
    case 0:
      return "/app";

    case 1:
      return "/template";

    case 2:
      return "/Org Rules";

    case 3:
      return "/Search";
    case 4:
      return "/Scaled Messages";
    default:
      break;
  }
};

const getMenuPath = (index) => {
  localStorage.clear();

  return "/";

  // switch (index) {
  //   case 0:
  //   // return "/profile";

  //   // case 1:
  //   //   return "/settings";

  //   case 2:
  //     // todo - update token and set isAuth = false
  //     return "/";

  //   default:
  //     break;
  // }
};

const SideBar = () => {
  let username = localStorage.getItem("username");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const { selected } = useSelector((state) => state.contactList);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    navigate();
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  function randomColor() {
    let hex = Math.floor(Math.random() * 0xffffff);
    let color = "#" + hex.toString(16);

    return color;
  }
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <Box
      p={2}
      sx={{
        backgroundColor: theme.palette.background.paper,
        boxShadow: "0px 0px 2px rgba(0,0,0,0.25)",
        height: "100vh",
        width: 100,
        display: "flex",
      }}>
      <Stack
        direction="column"
        alignItems={"center"}
        justifyContent="space-between"
        sx={{ width: "100%", height: "100%" }}
        spacing={3}>
        <Stack alignItems={"center"} spacing={4}>
          <Box
            sx={{
              // backgroundColor: "black",
              height: 60,
              width: 120,
              padding: 1,
              borderRadius: 1.5,
            }}>
            <img src={Logo} alt={"Logo icon"} />
          </Box>
          <Stack
            sx={{ width: "max-content" }}
            direction="column"
            alignItems="center"
            spacing={3}>
            {Nav_Buttons.map((el) =>
              el.index === selected ? (
                <Box
                  key={""}
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: 1.5,
                  }}>
                  <IconButton
                    sx={{ width: "max-content", color: "#fff" }}
                    key={el.index}>
                    {/* {el.icon} */}
                    <p style={{ fontSize: "14px", width: "100px" }}>
                      {el.icon}
                    </p>
                  </IconButton>
                </Box>
              ) : (
                <IconButton
                  onClick={() => {
                    dispatch(changeSelection(el.index));
                    navigate(getPath(el.index));
                  }}
                  sx={{
                    // width: "max-content",
                    color:
                      theme.palette.mode === "light"
                        ? "#000"
                        : theme.palette.text.primary,
                  }}
                  key={el.index}>
                  <p style={{ fontSize: "14px" }}>{el.icon}</p>
                </IconButton>
              )
            )}
            {/* <Divider sx={{ width: "48px" }} /> */}
            {
              selected === 3 && (
                <Box
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    borderRadius: 1.5,
                  }}>
                  <IconButton sx={{ width: "max-content", color: "#fff" }}>
                    <Gear />
                  </IconButton>
                </Box>
              )
              // : (
              //   <IconButton
              //     onClick={() => {
              //       setSelected(3);
              //       navigate(getPath(3));
              //     }}
              //     sx={{
              //       width: "max-content",
              //       color:
              //         theme.palette.mode === "light"
              //           ? "#000"
              //           : theme.palette.text.primary,
              //     }}>
              //     <Gear />
              //   </IconButton>
              // )
            }
          </Stack>
        </Stack>

        <Stack spacing={4}>
          {/* <AntSwitch
            onChange={() => {
              onToggleMode();
            }}
            defaultChecked
          /> */}
          <ThemeSettings />

          <Avatar
            id="basic-button"
            sx={{
              backgroundColor: randomColor(),
              color: "#fff",
              cursor: "pointer",
            }}
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}>
            <Avatar
              sx={{
                backgroundColor: randomColor(),
                color: "#fff",
              }}></Avatar>
          </Avatar>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            transformOrigin={{ vertical: "bottom", horizontal: "left" }}>
            <Stack spacing={1} px={1}>
              {Profile_Menu.map((el, idx) => (
                <MenuItem
                  onClick={() => {
                    handleClick();
                  }}>
                  <Stack
                    onClick={() => {
                      navigate(getMenuPath(idx));
                    }}
                    sx={{ width: 100 }}>
                    <span>{username}</span>
                    <br />
                    <span>
                      {el.title} {el.icon}
                    </span>
                  </Stack>
                </MenuItem>
              ))}
            </Stack>
          </Menu>
        </Stack>
      </Stack>
    </Box>
  );
};

export default SideBar;
