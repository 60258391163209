import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedContact: {},
  chatList: [],
  selected: 0,
};

export const contactSlice = createSlice({
  name: "contacts",
  initialState,
  reducers: {
    handleContactData: (state, payload) => {
      state.selectedContact = payload.payload;
    },
    handleChat: (state, payload) => {
      state.chatList.data.push(payload.payload);
    },
    changeChat: (state, payload) => {
      state.chatList = payload.payload;
    },
    changeSelection: (state, payload) => {
      state.selected = payload.payload;
    },
  },
});

export const { handleContactData, handleChat, changeChat, changeSelection } =
  contactSlice.actions;

export default contactSlice.reducer;
