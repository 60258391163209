import { Container, Stack } from "@mui/material";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import Logo from "../../assets/Images/logo.png";

const isAuthenticated = false;

const MainLayout = () => {
  // if (isAuthenticated) {
  //   return <Navigate to="/app" />;
  // }

  return (
    <>
      <Container sx={{ mt: 10 }} maxWidth="sm">
        <Stack spacing={5}>
          <Stack direction="column" alignItems={"center"}>
            <img
              style={{
                // height: 150,
                width: 250,
                // background: "black",
                margin: 0,
                borderRadius: "4px",
                marginBottom: "40px",
              }}
              src={Logo}
              alt="Logo"
            />
          </Stack>
        </Stack>
        <Outlet />
      </Container>
    </>
  );
};

export default MainLayout;
