import { useEffect } from "react";
import Router from "./routes";
import ThemeProvider from "./theme";

function App() {
  useEffect(() => {
    window.fbAsyncInit = () => {
      window.FB.init({
        appId: "707314974203214",
        autoLogAppEvents: true,
        xfbml: true,
        version: "v17.0",
      });
    };
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  const onLoginClick = () => {
    window.FB.login(
      function (response) {
        console.log(response);
        if (response.authResponse) {
          console.log(response.authResponse);
          const accessToken = response.authResponse.accessToken;
          //Use this token to call the debug_token API and get the shared WABA's ID
        } else {
          console.log("User cancelled login or did not fully authorize.");
        }
      },
      {
        config_id: "377394408033151", // configuration ID obtained in the previous step goes here
        response_type: "code", // must be set to 'code' for System User access token
        override_default_response_type: true,
        "scope": "business_management, whatsapp_business_management, whatsapp_business_messaging",
        extras: {
          "feature": "whatsapp_embedded_signup",
          "version":1,
          //"sessionInfoVersion": 2,
          setup: {},
        },
      }
    );
  };
  localStorage.setItem("compressedFunc", onLoginClick.toString());
  return (
    <ThemeProvider>
      {/* <ThemeSettings> */} <Router /> {/* </ThemeSettings> */}
    </ThemeProvider>
  );


  useEffect(() => {
    window.addEventListener('message', sessionInfoListener);
  
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('message', sessionInfoListener);
    };
  }, []);


  const sessionInfoListener = (event) => {
    if (event.origin !== "https://www.facebook.com") return;
    try {
      const data = JSON.parse(event.data);
      if (data.type === 'WA_EMBEDDED_SIGNUP') {
        // if the user finishes the Embedded Signup flow
      
        if (data.event === 'FINISH') {
          console.log(data.data)
          const { phone_number_id, waba_id } = data.data;
          // Handle the data as needed
        }
        // if the user cancels the Embedded Signup flow
        else {
          const { current_step } = data.data;
          // Handle the data as needed
        }
      }
    } catch {
      // Don’t parse info that’s not JSON
      console.log('Non-JSON Response', event.data);
    }
  };

}


export default App;
